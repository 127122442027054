import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import * as Sentry from '@sentry/react';
import { ToastProvider } from '@/Providers/ToastProvider';
const appName = import.meta.env.VITE_APP_NAME || 'MailWizard';

const params = new URLSearchParams(window.location.search);
const popup = params.get('popup');
if (popup) {
    sessionStorage.setItem('popup', popup);
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob('./Pages/**/*.tsx')
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                new Sentry.BrowserTracing({
                    tracePropagationTargets: [
                        'localhost',
                        /^https:\/\/app\.mailwizard\.ai/,
                    ],
                }),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        root.render(
            <ToastProvider>
                <App {...props} />
            </ToastProvider>
        );
    },
    progress: {
        color: '#ff0968',
    },
});
